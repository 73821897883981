import { FC } from "react";
import { DossierGarantie, EtatReglementDossierGarantie } from "domain/api-schemas";
import { TextV2, Tag, ButtonV2, Message, Divider, IconV2 } from "@roole/components-library";
import styles from "./Remboursement.module.scss";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { currentContratQuery } from "context/currentContratState/selector";
import { useRecoilValue } from "recoil";
import { ibanFormat } from "domain/service/Sinistre/useDossier";
import { getShortMaskedIban } from "utils/MaskedIban";

interface RemboursementProps {
  dossierData: DossierGarantie;
}

export const Remboursement: FC<RemboursementProps> = ({ dossierData }) => {
  const navigate = useNavigate();

  const place = dossierData?.modeReglement.valeur === "Virement" ? "votre banque." : "la poste.";

  const currentContratState = useRecoilValue(currentContratQuery);
  const { membre } = currentContratState ?? {};

  const messageRegle =
    dossierData?.modeReglement.valeur === "Virement" ? (
      <div>
        <TextV2 textVariant="body-small">
          C'est tout bon, nous avons procédé au remboursement !
        </TextV2>
        <div>
          <TextV2 textVariant="body-small" as="span">
            L'opération peut prendre{" "}
          </TextV2>
          <TextV2 textVariant="body-small-em" as="span">
            jusqu'à 48h pour arriver sur le compte concerné.
          </TextV2>
        </div>
        <TextV2 textVariant="body-small" as="span">
          Le règlement est parti, il est maintenant entre les mains de {place}
        </TextV2>
      </div>
    ) : (
      <div>
        <TextV2 textVariant="body-small" as="div">
          C'est tout bon ! Nous avons procédé au remboursement.
        </TextV2>
        <TextV2 textVariant="body-small" as="div">
          Le règlement est parti, il est maintenant entre les mains de {place}
        </TextV2>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <TextV2 textVariant="heading-3">Détail du remboursement</TextV2>
        <Tag
          type="validation"
          label={
            dossierData?.etatReglement?.code === EtatReglementDossierGarantie.Regle
              ? "Réglé"
              : "Accordé"
          }
        />
      </div>
      <div className={styles.category}>
        <TextV2 textVariant="body-medium">Montant remboursé</TextV2>
        <div className={styles.price}>
          <IconV2 name="rachat-de-franchise1" color="positive" />
          <TextV2 textVariant="body-medium-em">{dossierData?.montantARegler} €</TextV2>
        </div>
      </div>
      <Divider variant="light" />
      <div className={styles.category}>
        <TextV2 textVariant="body-medium">Mode de remboursement</TextV2>
        <TextV2 textVariant="body-medium" color="secondary">
          {dossierData?.modeReglement.valeur === "Virement" ? "Virement bancaire" : "Chèque"}
        </TextV2>
      </div>
      <Divider variant="light" />
      <div className={styles.category}>
        <TextV2 textVariant="body-medium">Titulaire</TextV2>
        <TextV2 textVariant="body-medium" color="secondary">
          {dossierData?.coordonneesBancaires?.titulaire || membre?.nom}
        </TextV2>
      </div>
      <Divider variant="light" />
      <div className={styles.category}>
        <TextV2 textVariant="body-medium">
          {dossierData?.modeReglement.valeur === "Virement" ? "IBAN" : "Adresse"}
        </TextV2>
        <TextV2 textVariant="body-medium" color="secondary" className={styles.address}>
          {dossierData?.modeReglement.valeur === "Virement" ? (
            ibanFormat(getShortMaskedIban(dossierData?.coordonneesBancaires?.iban))
          ) : (
            <>
              {membre?.rue}
              {membre?.batimentResidence && (
                <>
                  <br />
                  {membre?.batimentResidence}
                </>
              )}
              {membre?.infoEscPorteRemise && (
                <>
                  <br />
                  {membre?.infoEscPorteRemise}
                </>
              )}
              {membre?.lieuditBpHameau && (
                <>
                  <br />
                  {membre?.lieuditBpHameau}
                </>
              )}
              {membre?.ville && (
                <>
                  <br />
                  {membre?.ville + " " + membre?.codePostal}
                </>
              )}
            </>
          )}
        </TextV2>
      </div>
      <div className={styles.message}>
        <Message
          icon="app-icon"
          title="Roole"
          iconBgColor="brand"
          message={
            <TextV2 textVariant="body-small">
              {dossierData?.etatReglement?.code === EtatReglementDossierGarantie.Regle
                ? messageRegle
                : "Nous allons procéder au remboursement dans les prochains jours."}
            </TextV2>
          }
          type="information"
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonV2
          variant="secondary-dark"
          size="large"
          onClick={() => navigate(RoutePaths.SINISTRES)}
        >
          Retour à mes sinistres
        </ButtonV2>
      </div>
    </div>
  );
};

export default Remboursement;
