import React, { FC, useState, useRef, useEffect } from "react";
import {
  DrawerMobile,
  Message,
  TextV2,
  Tile,
  FileUploader,
  FileUploaderRef,
  UploadState,
  ButtonV2,
} from "@roole/components-library";
import styles from "./UploadDrawer.module.scss";
import { addPj } from "domain/api/Sinistre/AddPj";
import { DossierGarantie } from "domain/api-schemas";
import { validateAndSetFile, handleDelete, UploadedFile, AUTRES_TYPE_ID } from "./utils";
import { sendDossierEditedEvent } from "utils/sendEventToApp";
import { useAuthenticationContext } from "AuthenticationProvider";

interface UploadDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedPJ: { nom: string; description?: string; typeId?: string } | null;
  onFilesUpload: (files: UploadedFile[]) => void;
  dossierData: DossierGarantie;
  idAdhesion: number;
}

const MAX_FILE_SIZE = 5242880;
const ACCEPTED_FORMATS = ["image/jpeg", "image/png", "application/pdf"];

export const UploadDrawer: FC<UploadDrawerProps> = ({
  isOpen,
  handleClose,
  selectedPJ,
  onFilesUpload,
  dossierData,
  idAdhesion,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fileUploaderRef = useRef<FileUploaderRef>(null);

  const { isJwtAuthenticated } = useAuthenticationContext();

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(null);
      setUploadedFiles([]);
    }
  }, [isOpen]);

  const handleFileChange = async (state: UploadState) => {
    const fileData = state.files[state.files.length - 1];
    const file = fileData?.ref;
    if (file) {
      if (uploadedFiles.find((f) => f.name === file.name)) {
        return;
      }
      if (!ACCEPTED_FORMATS.includes(file.type)) {
        setErrorMessage("Ce type de format n’est pas accepté.");
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("Ce fichier dépasse la taille maximum autorisée.");
        return;
      }
      const pjType = selectedPJ ? { typeId: selectedPJ.typeId } : { typeId: AUTRES_TYPE_ID };
      await validateAndSetFile(file, setErrorMessage, setUploadedFiles, pjType);
    }
  };

  const handleValidate = () => {
    sendDossierEditedEvent();
    uploadedFiles.forEach((file) => {
      addPj({
        idAdhesion: idAdhesion,
        TypeId: file.typeId,
        DossierGarantieId: dossierData.crmId,
        EvenementId: dossierData.evenementId,
        Nom: file.name.split(".").slice(0, -1).join("."),
        Contenu: file.base64.split(",")[1],
        Extension: `.${file.name.split(".").pop()}`,
        Categorie: "Sinistre",
        Source: isJwtAuthenticated ? "RoolePremium" : "EspaceMembre",
      });
    });
    onFilesUpload(uploadedFiles);
    setUploadedFiles([]);
    handleClose();
  };

  const renderUploadedPJTiles = () => {
    return uploadedFiles.map((file) => (
      <Tile
        key={file.name}
        title={`${file.name} (${(file.size / 1024).toFixed(0)} Ko)`}
        subtitle={`Ajouté le ${file.date}`}
        avatar="file"
        icon="trash"
        onClick={() => handleDelete(file.name, setUploadedFiles)}
      />
    ));
  };

  const primaryLabel = uploadedFiles.length > 0 ? "Valider" : "";

  return (
    <DrawerMobile
      title="Ajouter un document"
      isOpen={isOpen}
      handleClose={handleClose}
      content={
        <div className={styles.modal}>
          <Message
            icon="info"
            type="information"
            title="Consignes d'importation :"
            message={
              <>
                <TextV2 textVariant="body-small">
                  · Les formats acceptés sont : JPG, PNG, PDF.
                </TextV2>
                <TextV2 textVariant="body-small">
                  · La taille maximum est de : 5 Mo par fichier.
                </TextV2>
                <TextV2 textVariant="body-small">
                  Assurez-vous que votre document n’est pas tronqué, ne comporte pas de flou ou de
                  reflet et qu’il est lisible.
                </TextV2>
              </>
            }
          />
          <div className={styles.selectedPJ}>
            <TextV2 textVariant="body-medium-em">{selectedPJ?.nom || ""}</TextV2>
          </div>
          {uploadedFiles.length > 0 && (
            <div className={styles.selectedPJ}>{renderUploadedPJTiles()}</div>
          )}
          <div className={styles.pictureContainer}>
            <FileUploader
              ref={fileUploaderRef}
              uploadedFiles={[]}
              maxTotalSize={MAX_FILE_SIZE}
              maxSize={MAX_FILE_SIZE}
              subtitle={" "}
              onUploadFiles={async (files) => {
                return Promise.resolve();
              }}
              dropZoneLabel="Cliquez-ici pour ajouter vos fichiers"
              onChange={handleFileChange}
              withFileList={false}
            />
          </div>
          {errorMessage && (
            <div className={styles.errorMessage}>
              <Message
                icon="info"
                type="error"
                message={
                  <TextV2 textVariant="body-small" className={styles.errorText}>
                    {errorMessage}
                  </TextV2>
                }
              />
            </div>
          )}
          <div className={styles.buttons}>
            {primaryLabel && (
              <ButtonV2 variant="primary-brand" onClick={handleValidate}>
                {primaryLabel}
              </ButtonV2>
            )}
            <ButtonV2 variant="secondary-dark" onClick={handleClose}>
              Annuler
            </ButtonV2>
          </div>
        </div>
      }
    />
  );
};
