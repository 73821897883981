import { FC, useState, useEffect } from "react";
import { DossierGarantie, PjDescription, PieceDossier } from "domain/api-schemas";
import {
  TextV2,
  ButtonV2,
  IconV2,
  Tile,
  ButtonGroup,
  Drawer,
  DrawerMobile,
  ModalV2,
  Message,
} from "@roole/components-library";
import { formatDate } from "utils/formatDate";
import { sendCloseWebViewEvent, sendDossierEditedEvent } from "utils/sendEventToApp";
import { TilePJ } from "../TilePJ";
import styles from "./Content.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import { UploadModal } from "./Upload/UploadModal";
import { UploadDrawer } from "./Upload/UploadDrawer";
import { listDescriptionPjQuery } from "context/descriptionPjState/selector";
import { showPjApi } from "domain/api/Sinistre/ShowPjApi";
import { DemandeEtude } from "domain/api/Sinistre/DemandeEtude";
import { useRecoilValueLoadable } from "recoil";
import { LoadableState } from "context/state.types";
import { RoutePaths } from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import {
  calculNbPjSentOld,
  handleViewDocument,
  matchPjDescription,
  stripHtmlTags,
  UploadedFile,
} from "domain/service/Sinistre/useDossier";
import { monContratWithEcheanceState } from "context/currentContratWithEcheanceState/atom";
import { useRecoilValue } from "recoil";
import { useAuthenticationContext } from "AuthenticationProvider";

interface ContentProps {
  dossierData: DossierGarantie;
  isDossierOld: boolean;
}

const AUTRES_TYPE_ID = "C0459527-8137-EF11-8697-005056B0AF88";

export const Content: FC<ContentProps> = ({ dossierData, isDossierOld }) => {
  const monContrat = useRecoilValue(monContratWithEcheanceState);
  const { idAdhesion, identifiantContrat, membre } = monContrat ?? {};
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [titleInfo, setTitleInfo] = useState<string>("");
  const [selectedPJ, setSelectedPJ] = useState<{
    nom: string;
    typeId?: string;
    description?: string;
  } | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [allDocumentsAdded, setAllDocumentsAdded] = useState(false);
  const [currentInfoPj, setCurrentInfoPj] = useState<PjDescription | null>(null);
  const [isDemandeEtudeOpen, setIsDemandeEtudeOpen] = useState(false);

  const { isJwtAuthenticated } = useAuthenticationContext();

  const PJBloquants =
    dossierData.piecesAttendues?.filter((piece) => piece.isBloquant === true) || [];

  const pjInformations = useRecoilValueLoadable(
    listDescriptionPjQuery(PJBloquants.map((pj) => pj.typeId)),
  );

  const nbPJTotal = PJBloquants?.length ?? 0;

  let nbSent = PJBloquants.reduce((acc, pj) => {
    const nbUploadedFiles =
      uploadedFiles.filter(
        (file) => file.typeId?.toLocaleLowerCase() === pj.typeId?.toLocaleLowerCase(),
      ).length || 0;

    const nbPieceDossier =
      dossierData.piecesDossiers?.filter(
        (piece) => piece.typeId?.toLocaleLowerCase() === pj.typeId?.toLocaleLowerCase(),
      ).length || 0;

    return acc + Math.min(nbUploadedFiles + nbPieceDossier, 1);
  }, 0);

  if (isDossierOld) {
    const nbSentOld = uploadedFiles.length + calculNbPjSentOld(dossierData, nbPJTotal);
    nbSent = nbSentOld > nbPJTotal ? nbPJTotal : nbSentOld;
  }

  const percentage = (nbSent * 100) / nbPJTotal;

  const handleViewDocumentWithApi = async (pieceDossier: PieceDossier) => {
    try {
      const response = await showPjApi.fetchShowPj(
        pieceDossier.sharepointId || pieceDossier.blobNom || "",
      );
      const typePj = pieceDossier.nom.split(".").pop();
      const mimeType = typePj === "pdf" ? "application/pdf" : `image/${typePj}`;
      const base64 = `data:${mimeType};base64,${response}`;
      const newFile = {
        name: pieceDossier.nom,
        base64,
        size: 0,
        date: formatDate(new Date()),
        typeId: pieceDossier.typeId || "",
      };
      handleViewDocument(newFile, isMobile);
    } catch (error) {
      console.error("Error fetching document", error);
    }
  };

  const autresDocuments =
    dossierData.piecesDossiers?.filter(
      (piece) =>
        piece.typeId &&
        !PJBloquants.some(
          (pj) =>
            pj.typeId?.toLocaleLowerCase() === piece.typeId?.toLocaleLowerCase() ||
            piece.typeId?.toLocaleLowerCase() === AUTRES_TYPE_ID,
        ),
    ) || [];

  const handleOpenModal = (pj: { nom: string; typeId?: string }) => {
    setSelectedPJ(pj);
    setIsModalOpen(true);
  };

  const renderPjPieces = (typeId: string) => {
    const filteredPieces = dossierData.piecesDossiers?.filter(
      (piece) => piece.typeId?.toLocaleLowerCase() === typeId.toLocaleLowerCase(),
    );

    const filteredUploadedFiles = uploadedFiles.filter(
      (file) => file.typeId?.toLocaleLowerCase() === typeId.toLocaleLowerCase(),
    );

    if ((!filteredPieces || filteredPieces.length === 0) && filteredUploadedFiles.length === 0) {
      return (
        <TextV2 textVariant="body-small" color="secondary">
          Aucun document ajouté
        </TextV2>
      );
    }

    return (
      <>
        {filteredPieces?.map((piece, index) => (
          <div key={`${piece.typeId}-${index}`}>
            <Tile
              title={piece.nom}
              subtitle={`Ajouté le ${formatDate(piece.dateAjout || piece.dateCreation)}`}
              avatar="file"
              icon="eye"
              label={isMobile ? "" : "Voir"}
              onClick={() => handleViewDocumentWithApi(piece)}
            />
          </div>
        ))}
        {filteredUploadedFiles.map((file, index) => (
          <div key={`${file.name}-${index}`}>
            <Tile
              title={file.name}
              subtitle={`Ajouté le ${file.date}`}
              avatar="file"
              icon="eye"
              label={isMobile ? "" : "Voir"}
              onClick={() => handleViewDocument(file, isMobile)}
            />
          </div>
        ))}
      </>
    );
  };

  const handleFilesUpload = (files: UploadedFile[]) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    setIsModalOpen(false);
  };

  const handleSendDocuments = () => {
    sendDossierEditedEvent();
    DemandeEtude({
      idAdhesion: Number(idAdhesion),
      dossierGarantie: {
        crmId: dossierData.crmId,
        nom: dossierData.nom,
      },
      garantie: {
        crmId: dossierData.garantie?.crmId,
        nom: dossierData.garantie?.nom,
      },
      evenement: {
        crmId: dossierData.evenementId,
        nom: dossierData.evenementNom,
      },
      typeEvenement: {
        crmId: dossierData.typeEvenement.crmId,
        nom: dossierData.typeEvenement.nom,
      },
      identifiantContrat: identifiantContrat || "",
      nomMembre: `${membre?.nom} ${membre?.prenom}`,
      Source: isJwtAuthenticated ? "RoolePremium" : "EspaceMembre",
    }).then(() => {
      setIsDemandeEtudeOpen(false);
      window.location.reload();
    });
  };

  const allCategoriesHaveDocuments = () => {
    const categories = [...PJBloquants];
    return categories.every((category) => {
      const categoryFiles = dossierData.piecesDossiers?.some(
        (piece) => piece.typeId?.toLocaleLowerCase() === category.typeId?.toLocaleLowerCase(),
      );
      const uploadedCategoryFiles = uploadedFiles.some(
        (file) => file.typeId?.toLocaleLowerCase() === category.typeId?.toLocaleLowerCase(),
      );
      return categoryFiles || uploadedCategoryFiles;
    });
  };

  useEffect(() => {
    setAllDocumentsAdded(allCategoriesHaveDocuments());
  }, [dossierData, uploadedFiles]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.content}>
      <TextV2 textVariant="heading-2">Pièces justificatives</TextV2>
      <TextV2 textVariant="body-medium" extraCss={{ marginTop: 8 }}>
        Ajoutez les documents nécessaires au traitement de votre dossier
      </TextV2>
      <TilePJ
        percentage={!isNaN(percentage) ? Math.floor(percentage) : 0}
        nbSent={nbSent || 0}
        nbTotal={nbPJTotal}
        title="Complétez votre dossier"
        action={
          <ButtonV2
            variant="primary-brand"
            size="small"
            disabledLight={!allDocumentsAdded && !isDossierOld}
            onClick={() => (allDocumentsAdded || isDossierOld) && setIsDemandeEtudeOpen(true)}
          >
            Envoyer
          </ButtonV2>
        }
      />
      {isDossierOld && (
        <div style={{ marginTop: 24 }}>
          <Message
            message={
              <TextV2>
                Suite à la mise à jour de notre outil de suivi de sinistre, les documents que vous
                avez importés ont été déplacés dans la section “Autres documents”. Vous pouvez
                ajouter des documents ou envoyer votre dossier.
              </TextV2>
            }
            type={"warning"}
            icon="info"
          />
        </div>
      )}
      <div>
        <div>
          {PJBloquants?.map((pj, index) => {
            const pjInfo =
              pjInformations.state === LoadableState.Has_Value &&
              Array.isArray(pjInformations?.contents) &&
              matchPjDescription(pj.typeId, pjInformations?.contents);

            return (
              <div key={`${pj.typeId}-${index}`}>
                <div className={styles.pjHeader}>
                  <div className={styles.pjTitle}>
                    <TextV2 textVariant="body-medium-em">{pj.nom}</TextV2>
                    <IconV2
                      name="help-circle"
                      size={28}
                      onClick={() => {
                        if (pjInfo) {
                          setIsInfoModalOpen(true);
                          setTitleInfo(pj.nom);
                          setCurrentInfoPj(pjInfo);
                        }
                      }}
                      color={pjInfo ? "dark" : "neutral"}
                      extraCss={{ cursor: "pointer" }}
                    />
                  </div>
                  <ButtonV2
                    variant="tertiary-brand"
                    icon="plus"
                    onClick={() => handleOpenModal(pj)}
                    size="small"
                  >
                    Ajouter
                  </ButtonV2>
                </div>
                <div className={styles.pjList}>{renderPjPieces(pj.typeId)}</div>
              </div>
            );
          })}
          <div className={styles.pjHeader}>
            <div className={styles.pjTitle}>
              <TextV2 textVariant="body-medium-em">Autres documents</TextV2>
              <TextV2 textVariant="body-medium">(Optionnel)</TextV2>
            </div>
            <ButtonV2
              variant="tertiary-brand"
              icon="plus"
              onClick={() =>
                handleOpenModal({
                  nom: "Autres documents",
                  typeId: AUTRES_TYPE_ID,
                })
              }
              size="small"
            >
              Ajouter
            </ButtonV2>
          </div>
          <div className={styles.pjList}>
            {autresDocuments.length === 0 &&
            uploadedFiles.filter((file) => file.typeId === AUTRES_TYPE_ID).length === 0 ? (
              <TextV2 textVariant="body-small" color="secondary">
                Aucun document ajouté
              </TextV2>
            ) : (
              <>
                {autresDocuments.map((piece, index) => (
                  <div key={`${piece.nom}-${index}`}>
                    <Tile
                      title={piece.nom}
                      subtitle={`Ajouté le ${formatDate(piece.dateAjout || piece.dateCreation)}`}
                      avatar="file"
                      icon="eye"
                      label={isMobile ? "" : "Voir"}
                      onClick={() => handleViewDocumentWithApi(piece)}
                    />
                  </div>
                ))}
                {uploadedFiles
                  .filter((file) => file.typeId === AUTRES_TYPE_ID)
                  .map((file, index) => (
                    <div key={`${file.name}-${index}`}>
                      <Tile
                        title={file.name}
                        subtitle={`Ajouté le ${file.date}`}
                        avatar="file"
                        icon="eye"
                        label={isMobile ? "" : "Voir"}
                        onClick={() => handleViewDocument(file, isMobile)}
                      />
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <div>
            <ButtonGroup
              size="large"
              direction={isMobile ? "column" : "row"}
              buttonSecondary={{
                variant: "secondary-dark",
                onClick: () => {
                  sendCloseWebViewEvent({ hasError: false });
                  if (!isJwtAuthenticated) {
                    navigate(RoutePaths.SINISTRES);
                  }
                },
                children: "Retour à mes sinistres",
              }}
              buttonPrimary={{
                variant: "primary-brand",
                children: "Envoyer mon dossier",
                onClick: () => (allDocumentsAdded || isDossierOld) && setIsDemandeEtudeOpen(true),
                disabledLight: !allDocumentsAdded && !isDossierOld,
              }}
            />
          </div>
          <TextV2 textVariant="body-small" className={styles.footerText}>
            Pour envoyer, veuillez ajouter les documents nécessaires au traitement.
          </TextV2>
        </div>
      </div>
      {isMobile ? (
        <UploadDrawer
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          selectedPJ={selectedPJ}
          idAdhesion={Number(idAdhesion)}
          onFilesUpload={handleFilesUpload}
          dossierData={dossierData}
        />
      ) : (
        <UploadModal
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          selectedPJ={selectedPJ}
          onFilesUpload={handleFilesUpload}
          dossierData={dossierData}
          idAdhesion={Number(idAdhesion)}
        />
      )}
      {isMobile ? (
        <DrawerMobile
          title={currentInfoPj?.nom ?? titleInfo}
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
          content={<div>{currentInfoPj && stripHtmlTags(currentInfoPj.description)}</div>}
        />
      ) : (
        <Drawer
          title={currentInfoPj?.nom ?? titleInfo}
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
          content={<div>{currentInfoPj && stripHtmlTags(currentInfoPj.description)}</div>}
        />
      )}
      {isMobile ? (
        <DrawerMobile
          title={"Confirmer l'envoi"}
          isOpen={isDemandeEtudeOpen}
          handleClose={() => setIsDemandeEtudeOpen(false)}
          content={
            <div className={styles.modal}>
              <TextV2 textVariant="body-medium">
                En confirmant, votre dossier sera envoyé à l'un de nos conseillers pour être étudié.
                Vous n'aurez plus la possibilité d'ajouter de documents.
              </TextV2>
              <div className={styles.buttonsMobile}>
                <ButtonV2 variant="primary-brand" onClick={handleSendDocuments}>
                  Confirmer
                </ButtonV2>
                <ButtonV2 variant="secondary-dark" onClick={() => setIsDemandeEtudeOpen(false)}>
                  Annuler
                </ButtonV2>
              </div>
            </div>
          }
        />
      ) : (
        <ModalV2
          title={"Confirmer l'envoi"}
          isOpen={isDemandeEtudeOpen}
          handleClose={() => setIsDemandeEtudeOpen(false)}
          content={
            <div className={styles.modal}>
              <TextV2 textVariant="body-medium">
                En confirmant, votre dossier sera envoyé à l'un de nos conseillers pour être étudié.
                Vous n'aurez plus la possibilité d'ajouter de documents.
              </TextV2>
              <div className={styles.buttons}>
                <ButtonV2 variant="primary-brand" onClick={handleSendDocuments}>
                  Confirmer
                </ButtonV2>
                <ButtonV2 variant="secondary-dark" onClick={() => setIsDemandeEtudeOpen(false)}>
                  Annuler
                </ButtonV2>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
