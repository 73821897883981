import { Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { PublicRoutePaths, RoutePaths } from "./routePaths";
import { Page as RegularisationPage } from "pages/Regularisation/Page";
import { Page as ResiliationPage } from "pages/Resilier/Page";
import { Page as ConfirmerResiliationPage } from "pages/ConfirmerResiliation/Page";
import ConfirmerResiliationError from "pages/ConfirmerResiliation/ConfirmerResiliationError";
import { Page as HelpPage } from "pages/Help/Page";
import { Page as ReconductionPage } from "pages/Reconduction/Page";
import { Page as GarantiesPage } from "pages/Garanties/Page";
import { Page as ParrainagePage } from "pages/Parrainage/Page";
import { Page as SelectContractPage } from "pages/SelectContract/Page";
import { Page as AddContratPage } from "pages/AddContract/Page";
import { Page as ContractHomePage } from "pages/ContractHome/Page";
import { Page as SinistresPage } from "pages/Sinistres/Page";
import { Page as DetailSinistrePage } from "pages/Sinistres/Detail/Page";
import { OnBoarding as DeclarerPage } from "pages/Sinistres/Declarer/OnBoarding";
import { Page as DeclarerCommencer } from "pages/Sinistres/Declarer/Page";
import HomePage from "pages/HomePage";
import BonsPlans from "pages/BonPlans/BonsPlans";
import ContratInfos from "pages/Contrat/Page";
import PageLayout from "components/molecules/Layout/PageLayout";
import { PageConfirmationCRM } from "pages/Reconduction/PageConfirmationCRM";
import { PageAbandonCRM } from "pages/Reconduction/PageAbandonCRM";
import { MyGarage } from "pages/MyGarage/MyGarage";
import { ReportClaimGuard } from "pages/ReportClaim/ReportClaimGuard";
import { ClaimMonitoringGuard } from "pages/ClaimMonitoring/ClaimMonitoringGuard";
import { PageGuard as ContactUsGuard } from "pages/ContactUs/PageGuard";
import { PageConfirmationTW } from "pages/BonPlans/TotalWash/PageConfirmationTW";
import { PageAbandonTW } from "pages/BonPlans/TotalWash/PageAbandonTW";
import PageErrorTW from "pages/BonPlans/TotalWash/PageErrorTW";
import PaiementResult from "pages/BonPlans/TotalWash/PaiementResult";
import ErrorPage from "pages/Error/ErrorPage";
import ProtectedRoute from "./ProtectedRoute";
import SignIn from "pages/Authentification/SignIn";
import { TunnelLayout } from "components/molecules/Layout/TunnelLayout";
import { ContactSuccessStep } from "pages/ContactUs/Steps/ContactSuccessStep";
import { ContactErrorStep } from "pages/ContactUs/Steps/ContactErrorStep";
import { ContactMailStep } from "pages/ContactUs/Steps/ContactMailStep";
import { ContactConfirmationStep } from "pages/ContactUs/Steps/ContactConfirmationStep";
import { ContactTelephoneStep } from "pages/ContactUs/Steps/ContactTelephoneStep";
import ContactTypeStep from "pages/ContactUs/Steps/ContactTypeStep";
import { ContactReasonStep } from "pages/ContactUs/Steps/ContactReasonStep";
import { ReportClaimDateStep } from "pages/ReportClaim/Steps/ReportClaimDateStep";
import { ReportClaimTypeStep } from "pages/ReportClaim/Steps/ReportClaimTypeStep";
import { ReportClaimGuaranteesStep } from "pages/ReportClaim/Steps/ReportClaimGuaranteesStep";
import { ReportClaimFilesStep } from "pages/ReportClaim/Steps/ReportClaimFilesStep";
import {
  ReportClaimSuccessStep,
  ReportClaimAttachmentStep,
} from "pages/ReportClaim/Steps/ReportClaimSuccessStep";
import { ReportClaimErrorStep } from "pages/ReportClaim/Steps/ReportClaimErrorStep";
import UserProfil from "pages/UserProfil/UserProfil";
import Garanties from "pages/Reconduction/Steps/Garanties";
import MoyenPaiment from "pages/Reconduction/Steps/MoyenPaiment";
import InfoPersonnelles from "pages/Reconduction/Steps/InfoPersonnelles";
import SignatureContrat from "pages/Reconduction/Steps/SignatureContrat";
import ReconductionConfirmation from "pages/Reconduction/Steps/ReconductionConfirmation";
import ReconductionConfirmationCancel from "pages/Reconduction/Steps/ReconductionConfirmationCancel";
import ReconductionConfirmationCb from "pages/Reconduction/Steps/ReconductionConfirmationCb";
import { RenewalRoutePath, RenewalWizard } from "pages/Reconduction/RenewalWizard";
import BonplanVinci from "pages/BonPlans/BonplanVinci";
import BonPlan from "pages/BonPlan/BonPlan";
import { RegularisationRoutePath } from "pages/Regularisation/RegularisationRoutePath";
import ApiHandler from "clientProvider/ApiHandler";

export const throughAppRoutes = () => (
  <>
    <Route element={<TunnelLayout />}>
      <Route
        path={RoutePaths.RESILIATION}
        element={<ProtectedRoute component={ResiliationPage} />}
      />
      <Route
        path={`${RoutePaths.DETAIL_SINISTRE}/:id`}
        element={<ProtectedRoute component={DetailSinistrePage} />}
      />
    </Route>
    <Route
      path="*"
      element={
        <RecoilRoot>
          <ErrorPage errorNumber={404} />
        </RecoilRoot>
      }
    />
  </>
);

export const privateRoutes = () => (
  <>
    <Route
      path={`${RegularisationRoutePath.BASE}/*`}
      element={
        <RecoilRoot>
          <RegularisationPage />
        </RecoilRoot>
      }
    />
    <Route element={<TunnelLayout />}>
      <Route
        path={RoutePaths.RESILIATION}
        element={<ProtectedRoute component={ResiliationPage} />}
      />
      <Route
        path={RoutePaths.CONTRAT_CHOISIR}
        element={<ProtectedRoute component={SelectContractPage} />}
      />
      <Route
        path={RoutePaths.CONTRAT_AJOUTER}
        element={<ProtectedRoute component={AddContratPage} />}
      />
      <Route
        path={`${RoutePaths.DETAIL_SINISTRE}/:id`}
        element={<ProtectedRoute component={DetailSinistrePage} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRES}
        element={<ProtectedRoute component={DeclarerPage} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRES_COMMENCER}
        element={<ProtectedRoute component={DeclarerCommencer} />}
      />
      {reconductionRoutes}
      {reportClaimState}
      {contactUsRoutes}
    </Route>
    <Route element={<PageLayout />}>
      <Route
        path={RoutePaths.CONTRAT_HOME}
        element={<ProtectedRoute component={ContractHomePage} />}
      />
      <Route path={RoutePaths.GARANTIES} element={<ProtectedRoute component={GarantiesPage} />} />
      <Route
        path={RoutePaths.PARRAINAGE}
        element={<ProtectedRoute component={ParrainagePage} path="parrainage" />}
      />
      <Route
        path={RoutePaths.BON_PLANS_HOME}
        element={<ProtectedRoute component={BonsPlans} path="mesBonsPlans" />}
      ></Route>
      <Route
        path={RoutePaths.BON_PLANS}
        element={<ProtectedRoute component={BonPlan} path="mesBonsPlans" />}
      ></Route>
      <Route path={RoutePaths.MON_COMPTE} element={<ProtectedRoute component={MyGarage} />} />
      <Route path={RoutePaths.CONTRAT} element={<ProtectedRoute component={ContratInfos} />} />
      <Route path={RoutePaths.SINISTRES} element={<ProtectedRoute component={SinistresPage} />} />
      <Route
        path={RoutePaths.MES_SINISTRES}
        element={<ProtectedRoute component={ClaimMonitoringGuard} />}
      />
      <Route path={RoutePaths.USER_PROFIL} element={<ProtectedRoute component={UserProfil} />} />
    </Route>
    <Route path={RoutePaths.HOME} element={<ProtectedRoute component={HomePage} />} />
    <Route path={RoutePaths.AIDE} element={<ProtectedRoute component={HelpPage} />} />
  </>
);

export const publicRoutes = () => (
  <>
    <Route
      path={`${PublicRoutePaths.CONFIRMER_RESILIATION}/:id/*`}
      element={
        <ApiHandler>
          <RecoilRoot>
            <ConfirmerResiliationPage />
          </RecoilRoot>
        </ApiHandler>
      }
    />
    <Route
      path={`${PublicRoutePaths.CONFIRMER_RESILIATION_ERROR}`}
      element={
        <RecoilRoot>
          <ConfirmerResiliationError />
        </RecoilRoot>
      }
    />
    <Route
      path={`${RenewalRoutePath.BASE}/:id/*`}
      element={
        <ApiHandler>
          <RecoilRoot>
            <RenewalWizard />
          </RecoilRoot>
        </ApiHandler>
      }
    />
    <Route
      path={PublicRoutePaths.Error}
      element={
        <RecoilRoot>
          <ErrorPage errorNumber={500} />
        </RecoilRoot>
      }
    />
    <Route path={PublicRoutePaths.SIGNIN} element={<SignIn />} />
    <Route
      path={PublicRoutePaths.RECONDUCTION_CRM_OK}
      element={
        <RecoilRoot>
          <PageConfirmationCRM />
        </RecoilRoot>
      }
    />
    <Route
      path={PublicRoutePaths.RECONDUCTION_CRM_KO}
      element={
        <RecoilRoot>
          <PageAbandonCRM />
        </RecoilRoot>
      }
    />
    <Route
      path={PublicRoutePaths.BONPLAN_VINCI}
      element={
        <RecoilRoot>
          <BonplanVinci />
        </RecoilRoot>
      }
    />
    <Route
      path={PublicRoutePaths.PAIEMENT_RESULT}
      element={
        <RecoilRoot>
          <PaiementResult />
        </RecoilRoot>
      }
    />
    <Route
      path={PublicRoutePaths.PAIEMENT_SUCCESS}
      element={
        <RecoilRoot>
          <PageConfirmationTW />
        </RecoilRoot>
      }
    />
    <Route
      path={PublicRoutePaths.PAIEMENT_ABANDON}
      element={
        <RecoilRoot>
          <PageAbandonTW />
        </RecoilRoot>
      }
    />
    <Route
      path={PublicRoutePaths.PAIEMENT_ERROR}
      element={
        <RecoilRoot>
          <PageErrorTW />
        </RecoilRoot>
      }
    />
    <Route
      path={"/"}
      element={
        <RecoilRoot>
          <SignIn />
        </RecoilRoot>
      }
    />
    <Route
      path="*"
      element={
        <RecoilRoot>
          <ErrorPage errorNumber={404} />
        </RecoilRoot>
      }
    />
  </>
);

const reconductionRoutes = (
  <Route element={<ProtectedRoute component={ReconductionPage} />}>
    <Route path={RoutePaths.RECONDUCTION_HOME} element={<ProtectedRoute component={Garanties} />} />
    <Route
      path={RoutePaths.RECONDUCTION_IBAN}
      element={<ProtectedRoute component={MoyenPaiment} />}
    />
    <Route
      path={RoutePaths.RECONDUCTION_INFOPERSO}
      element={<ProtectedRoute component={InfoPersonnelles} />}
    />
    <Route
      path={RoutePaths.RECONDUCTION_SIGNATURE}
      element={<ProtectedRoute component={SignatureContrat} />}
    />
    <Route
      path={RoutePaths.RECONDUCTION_RESULT_SUCCESS}
      element={<ProtectedRoute component={ReconductionConfirmation} />}
    />
    <Route
      path={RoutePaths.RECONDUCTION_RESULT_CANCELED}
      element={<ProtectedRoute component={ReconductionConfirmationCancel} />}
    />
    <Route
      path={RoutePaths.RECONDUCTION_CONFIRMATION_CB}
      element={<ProtectedRoute component={ReconductionConfirmationCb} />}
    />
  </Route>
);

const reportClaimState = (
  <>
    <Route element={<ProtectedRoute component={ReportClaimGuard} />}>
      <Route
        path={RoutePaths.DECLARER_SINISTRE_DATE}
        key={RoutePaths.DECLARER_SINISTRE_DATE}
        element={<ProtectedRoute component={ReportClaimDateStep} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRE_TYPE}
        key={RoutePaths.DECLARER_SINISTRE_TYPE}
        element={<ProtectedRoute component={ReportClaimTypeStep} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRE_GUARANTEES}
        key={RoutePaths.DECLARER_SINISTRE_GUARANTEES}
        element={<ProtectedRoute component={ReportClaimGuaranteesStep} />}
      />
      <Route
        path={`${RoutePaths.DECLARER_SINISTRE_FILES}/:id`}
        key={RoutePaths.DECLARER_SINISTRE_FILES}
        element={<ProtectedRoute component={ReportClaimFilesStep} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRE_FILES}
        key={RoutePaths.DECLARER_SINISTRE_FILES}
        element={<ProtectedRoute component={ReportClaimFilesStep} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRE_SUCCESS}
        key={RoutePaths.DECLARER_SINISTRE_SUCCESS}
        element={<ProtectedRoute component={ReportClaimSuccessStep} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRE_ATTACHMENT}
        key={RoutePaths.DECLARER_SINISTRE_ATTACHMENT}
        element={<ProtectedRoute component={ReportClaimAttachmentStep} />}
      />
      <Route
        path={RoutePaths.DECLARER_SINISTRE_ERROR}
        key={RoutePaths.DECLARER_SINISTRE_ERROR}
        element={<ProtectedRoute component={ReportClaimErrorStep} />}
      />
    </Route>
    <Route
      path={RoutePaths.DECLARER_SINISTRE_SUCCESS}
      key={RoutePaths.DECLARER_SINISTRE_SUCCESS}
      element={<ProtectedRoute component={ReportClaimSuccessStep} />}
    />
    <Route
      path={RoutePaths.DECLARER_SINISTRE_ERROR}
      key={RoutePaths.DECLARER_SINISTRE_ERROR}
      element={<ProtectedRoute component={ReportClaimErrorStep} />}
    />
  </>
);

const contactUsRoutes = (
  <>
    <Route element={<ProtectedRoute component={ContactUsGuard} />}>
      <Route
        path={RoutePaths.CONTACT_REASON}
        element={<ProtectedRoute component={ContactReasonStep} />}
      />
      <Route
        path={RoutePaths.CONTACT_TYPE}
        element={<ProtectedRoute component={ContactTypeStep} />}
      />
      <Route
        path={RoutePaths.CONTACT_TELEPHONE}
        element={<ProtectedRoute component={ContactTelephoneStep} />}
      />
      <Route
        path={RoutePaths.CONTACT_MAIL}
        element={<ProtectedRoute component={ContactMailStep} />}
      />
      <Route
        path={RoutePaths.CONTACT_CONFIRMATION}
        element={<ProtectedRoute component={ContactConfirmationStep} />}
      />
    </Route>
    <Route
      path={RoutePaths.CONTACT_SUCCESS}
      element={<ProtectedRoute component={ContactSuccessStep} />}
    />
    <Route
      path={RoutePaths.CONTACT_FAILURE}
      element={<ProtectedRoute component={ContactErrorStep} />}
    />
  </>
);
